import config from '../../../constants/config';
import colors from '../../../constants/colors';
import {
 RiArrowRightSLine,
 RiArrowLeftLine,
 RiArrowRightLine,
 RiFileShield2Line,
 RiCloseLine,
 RiSave2Line,
 RiDeleteBin5Line,

 RiAddCircleFill,
 RiAddCircleLine,

 RiCheckFill,
 RiCloseFill,

 RiToggleLine,
 RiToggleFill,
 RiImage2Fill
} from "react-icons/ri";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import loadingAnimation from '../../../animations/loading_circle.json';
import Lottie from "lottie-react";
import Resizer from 'react-image-file-resizer';

import DetailView from "../../../components/DetailView";
import DropDown from "../../../components/DropDown";
import SortItem from "../../../components/SortItem";


function Banners(props) {
  const {
    token, account,
    setModalPre,
    subview_data,
    searchQuery,
    setSettingsHeaderPre,
    showSettingsHeaderPre
  } = props;
  const [ banners, setBanners ] = useState([]);
  const [ urlSubs, setUrlSubs ] = useState([]);
  const [ urlSubSelected, setUrlSubSelected ] = useState();
  const [ subType, setSubType ] = useState('');

  const [isDragging, setIsDragging] = useState(false);
  const [isLeavingDropzone, setIsLeavingDropzone] = useState(false);

  const [timeoutId, setTimeoutId] = useState(null);

  const [ bannerSelected, setBannerSelected ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ subLoading, setSubLoading ] = useState(false);

  const [ hasSticker, setHasSticker ] = useState(false);

  const [ page, setPage ] = useState(1);
  const [ pages, setPages ] = useState(1);
  const [ modal, setModal ] = useState();

  const scrollView = useRef();
  const inputImage = useRef();

  const inputTitle = useRef();
  const inputSubtitle = useRef();

  const inputC2aText = useRef();
  const inputStickerPre = useRef();
  const inputStickerText = useRef();
  const inputStickerPost = useRef();

  const inputC2aUrl = useRef();

  const controller = new AbortController();


  const fetchSubItems = (type = '', query = '') => {

    setSubType(type);
    setSubLoading(true);
    axios.post( config.api.url + 'admin/subitems', {
      hash: token,
      type: type,
      query: query
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;


      if(success){
        const items = data.items;

        setUrlSubs(items);
      }

    })
    .catch(function (error) {
      setSubLoading(false);
    });
  }

  const setDetailsPre = (mod) => {

    const timeout = (modal) ? 300 : 0;
    if(modal){
      setModal(modalOld=>{
        return {
          ...modalOld,
          hide: true
        }
      });
   }

   if(mod){
     showSettingsHeaderPre(false);
   }else{
     showSettingsHeaderPre(true);
   }

    setTimeout( () => {
        setModal(mod);
    }, timeout)

    if(mod && mod.type){

      setBannerSelected({
        key: mod.type,
        value: mod.type_text
      })
    }

    if(mod && mod.c2a){

      if(mod.c2a.data){
        setUrlSubSelected({
          key: mod.c2a.data.key,
          value: mod.c2a.data.value
        });
      }

      // Get items
      const b_regex = /^(.*?)\//;
      const b_match = (mod.c2a.url).match(b_regex);
      let b_type = b_match ? b_match[1] : (mod.c2a.url);

      switch(b_type){
        case "blog":
          b_type = "blog";
        break;
        case "category":
          b_type = "category";
        break;
        case "product":
          b_type = "product";
        break;
      }

      fetchSubItems(b_type);

    }else{
      setUrlSubSelected();
    }

    if(mod && mod.sticker){
      setHasSticker(true);
    }else{
      setHasSticker(false);
    }

  }


  const selectImage = (file) => {

    if(file) {
        try {

           let type = 'JPEG';
           switch(file.type){
             case "image/png":
              type = 'PNG';
             break;
           }

            Resizer.imageFileResizer(
            file,
            1000,
            1000,
            type, 90,0,
            uri => {

              if(modal){
                setModal( modalOld => {
                  return {
                    ...modalOld,
                    image: {
                      uri: URL.createObjectURL(file),
                      base: uri
                    }
                  }
                })

              }
            },
            'base64',
            500,
            500,
            );
        }   catch(err) {

        }
    }
  }


  const removeBanner = (banner_id = 0) => {

    if(banner_id > 0){

      setBanners(bannersOld => {
        return bannersOld.filter((p, i) => p.id != banner_id)
      });

      setLoading(true);
      axios.post( config.api.url + 'admin/banners/delete', {
        hash: token,
        banner_id: banner_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        setTimeout( () => {
          setLoading(false);
        }, 1);

        if(success){

          fetchBanners(page, false);

        }

      })
      .catch(function (error) {
        setLoading(false);
      });

    }
  }

  const bannerSaveOrder = (items) => {

    const indexes = (items).map( (order, index) => {
      return order.id
    });

    axios.post( config.api.url + 'admin/banners/order', {
      hash: token,
      order: indexes
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){

      }

    })
    .catch(function (error) {
      setSubLoading(false);
    });
  }

  const bannerSave = () => {

    document.activeElement.blur();
    if(modal && modal.id >= 0){
      const title = inputTitle.current?.value;
      const subtitle = inputSubtitle.current?.value;
      const image = (modal.image) ? ((typeof modal.image == 'string') ? modal.image : (modal.image).base) : '';

      const c2a = (modal.c2a) ? JSON.stringify({
        text: modal.c2a.text,
        url: modal.c2a.url
      }) : '';
      const type = (bannerSelected) ? bannerSelected.key : 0;

      let canContn = true;
      let sticker = '';
      if(hasSticker){
        const sticker_raw = {
          pre: (inputStickerPre.current?.value).toUpperCase(),
          text: (inputStickerText.current?.value).toUpperCase(),
          post: (inputStickerPost.current?.value).toUpperCase()
        }

        if(sticker_raw.pre == '' || sticker_raw.text == '' || sticker_raw.post == ''){
          canContn = false;
          setModalPre({
            title: 'Oops.',
            subtitle: 'De sticker is niet volledig ingevuld.'
          })
        }

        sticker = JSON.stringify(sticker_raw);
      }

      if(canContn){
        setLoading(true);
        axios.post( config.api.url + 'admin/banners/save', {
          hash: token,
          banner_id: modal.id,
          image: image,
          title: title,
          subtitle: subtitle,
          type: type,
          sticker: sticker,
          c2a: c2a
        }, {
            crossdomain: true,
            headers: {
              'api': config.api.key
            }
        })
        .then((json) => {
          const { success, response, data } = json.data;

          setTimeout( () => {
            setLoading(false);
          }, 1);


          if(success){

            setDetailsPre();
            fetchBanners(page, false);

          }else{

            setModalPre({
              title: 'Oops.',
              subtitle: response
            })
          }

        })
        .catch(function (error) {
          setLoading(false);
        });
      }


    }

  }

  const toggleBannerActive = (banner_id = 0, banner_index) => {

    if(banner_id > 0){

      banners[banner_index].active = !banners[banner_index].active;

      if(modal){
        modal.active = banners[banner_index].active;
      }

      // setLoading(true);
      axios.post( config.api.url + 'admin/banners/toggle', {
        hash: token,
        banner_id: banner_id,
      }, {
          crossdomain: true,
          headers: {
            'api': config.api.key
          }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        // setTimeout( () => {
        //   setLoading(false);
        // }, 1);



        if(success){

          fetchBanners(page, false);
        }

      })
      .catch(function (error) {
        // setLoading(false);
      });

    }
  }

  const fetchBanners = (pageFinal = page, shouldLoad = true) => {

    if(shouldLoad){
      setLoading(true);
    }

    axios.post( config.api.url + 'admin/banners', {
      hash: token,
      query: searchQuery,
      page: pageFinal
    }, {
        signal: controller.signal,
        crossdomain: true,
        headers: {
          'api': config.api.key
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(shouldLoad){
        setTimeout( () => {
          setLoading(false);
        }, 1);
      }

      if(success){
        const banners = data.banners;
        const stats = data.stats;

        if(banners){
          setBanners(banners);
        }

        if(shouldLoad){
          scrollView.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }

        if(stats){
          const { pages } = stats;

          if(pages > 0){
            setPages(pages);
          }
        }

      }

    })
    .catch(function (error) {
      setLoading(false);
    });


  }


  const setPagePre = (page) => {

    window.history.pushState({"z":"z"}, null, config.homepage + 'account/banners/' + page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setPage(page);
    fetchBanners(page);
  }

  const bannersPagination = () => {

    let pageItems = [];
    const limit_low = (page - 2);
    const limit_high = (page + 2);

    if(banners.length == 0) return false;

    for(let p = 1; p <= pages; p++){

      if(page < 10){

        if(p <= 10){
          pageItems.push(p)
        }
      }else{
        if(p >= limit_low && p <= limit_high){
          pageItems.push(p)
        }
      }
    }

    if(pages > 10){
      if(page >= 10){
        pageItems.unshift('...');
        pageItems.unshift(1);
      }

      if(page < (pages - 2)){
        pageItems.push('...');
      }

      if(!(pageItems.includes(pages))){
        pageItems.push(pages);
      }
    }

    return (
      <div className="ProductsPaginationWrapper Normal">
        <div className="ProductsPagination">

        <a href={ config.homepage + 'account/banners/' + ((page > 1) ? (page - 1) : 1) } onClick={
          (event) => {

            if(page > 1){
              setPagePre(page - 1);
            }
            event.preventDefault();
          }
        } className={`ProductsPaginationArrow Left ${ (page == 1) ? 'Disabled' : '' } AccentHover`}>
            <RiArrowLeftLine />
            <div className="ProductsPaginationArrowText">Vorige</div>
          </a>
          { pageItems.map( (pagination, p_index) => {

            if(pagination == '...'){
              return (
                <a className="ProductsPaginationDots">...</a>
              )
            }else{
              return (
                <a href={ config.homepage + 'account/banners/' + (pagination) } onClick={
                  (event) => {

                    setPagePre(pagination);

                    event.preventDefault();
                  }
                } key={'pagination_' + (pagination) } className={`ProductsPaginationItem ${ (page == (pagination)) ? 'Selected' : ''}`}>{ pagination }</a>
              )
            }

          })
          }

          { (pages > 1) &&
          <a href={ config.homepage + 'account/banners/' + (page + 1) } onClick={
            (event) => {

              if(page < pages){
                setPagePre(page + 1);
              }

              event.preventDefault();
            }
          } className={`ProductsPaginationArrow Right ${ (page == (pages)) ? 'Disabled' : '' } AccentHover`}>
            <div className="ProductsPaginationArrowText">Volgende</div>
            <RiArrowRightLine />
          </a>
          }

        </div>
      </div>
    )
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setIsLeavingDropzone(false);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    const { relatedTarget } = e;

    // Check if the relatedTarget is null or outside the dropzone
    if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
      setIsDragging(false);
      setIsLeavingDropzone(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    if (!isLeavingDropzone) {
      setIsDragging(true);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setIsLeavingDropzone(false);

    const file = e.dataTransfer.files[0];

    if(file){
      selectImage(file)
    }
  };


  const modalView = () => {


    return (
        <div className={`ModalSide ${ (isDragging) ? 'Disable' : ''}`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}>
          <div className="ModalSideHeader">
            <div className="ModalSideHeaderTitle">Banner { (modal && modal.id > 0) ? 'bewerken' : 'aanmaken' }</div>

            { (modal && modal.id > 0) &&
            <a onClick={
              () => {

                if(modal && modal.id){
                  const modalIndex = (banners).findIndex( (p, i) => p.id == modal.id);
                  toggleBannerActive(modal.id, modalIndex);
                }

              }
            } className={`OrderItemOptionsButton ${ (modal.active) ? 'DeleteInverted' : 'AddInverted' }`}>
              { (modal.active)
              ?
              <RiToggleFill style={{ fontSize: 14 }} />
              :
              <RiToggleLine style={{ fontSize: 14 }} />
              }
              <span className="OrderItemOptionsButtonText">{ (modal.active) ? 'Deactiveren' : 'Activeren' }</span>
            </a>
            }

            { (modal && modal.id > 0) &&
            <a onClick={
              () => {

                setModalPre({
                  title: 'Verwijderen.',
                  subtitle: 'Ben je zeker dat je deze banner wilt verwijderen?',
                  content: (
                    <>
                      <div className="modalContentOptions NoBorder">
                        <a onClick={
                          () => {

                            removeBanner(modal.id);
                            setDetailsPre();
                            setModalPre();
                          }
                        } className="modalContentOptionsButton Delete">Verwijderen</a>
                        <a onClick={
                          () => {

                            setModalPre();
                          }
                        } className="modalContentOptionsButton">Annuleren</a>
                      </div>
                    </>
                  )
                })

              }
            } className="OrderItemOptionsButton Delete">
              <RiDeleteBin5Line style={{ fontSize: 14 }} />
            </a>
            }
            <a onClick={
              () => {
                setDetailsPre()
              }
            } className="ModalSideHeaderClose">
              <RiCloseLine />
            </a>
          </div>

          <div className="ModalSideContent">
            { (modal) &&
            <div
              className={`ModalSideContentImageWrapper ${ (isDragging) ? 'Enabled' : ''} `}
              onDrop={handleDrop}
            >
              {
              (isDragging)
              ?
              <div className="ModalSideContentImageDrop">
                <div className="ModalSideContentImageDropText">Laat de foto hier los om te selecteren.</div>
              </div>
              :
              <>
                <div className="ModalSideContentImage" style={{ backgroundImage: `url(${ (modal.image) ? ((typeof modal.image == 'string') ? (modal.image) : (modal.image.uri)) : '' })` }}>
                  <div className="ModalSideContentImageOverlay">
                    <div onClick={
                      () => {

                        inputImage.current?.click();

                      }
                    } className="ModalSideContentImageOverlayCircle">
                      <RiImage2Fill />
                      <input ref={ inputImage } type="file" accept=".jpg, .jpeg, .png, .gif" style={{ display: 'none' }} onChange={
                        (event) => {
                           const file = event.target?.files[0];

                           selectImage(file);
                        }
                      }/>
                    </div>
                  </div>
                </div>
                <div className="ModalSideContentImageDetails">
                  <div className="ModalSideContentImageDetailsText">{ (modal.image != '') ? 'Foto actief.' : 'Geen foto geselecteerd.' }</div>
                  <div className="ModalSideContentImageDetailsHint">Sleep een foto of klik op 'selecteer foto'.</div>
                  <div className="ModalSideContentImageDetailsOptions">
                    { (modal && modal.image && modal.image != '') &&
                    <a onClick={
                      () => {

                        setModal( modalOld => {
                          return {
                            ...modalOld,
                            image: ''
                          }
                        })

                      }
                    } className="ModalSideContentImageDetailsOptionsButton Delete">Verwijderen</a>
                    }
                    <a onClick={
                      () => {

                        inputImage.current?.click();
                      }
                    } className="ModalSideContentImageDetailsOptionsButton">Selecteer foto</a>
                  </div>
                </div>
              </>
              }
            </div>
            }

            { /*
            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Type</div>

                <DropDown
                  key={JSON.stringify(bannerSelected)}
                  selected={(bannerSelected) ? bannerSelected.value : 'Selecteer item'}
                  list={([{ key: 'product', value: 'Product' }]).map( (type, index) => { return { key: type.key, value: type.value }})}
                  onSelect={
                    (item) => {

                      setBannerSelected(item)
                    }
                  }
                />
              </div>
            </div>
            */ }

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Titel</div>
                <input
                  ref={inputTitle}
                  key={ (modal && modal.title) ? modal.title : '' }
                  defaultValue={ (modal && modal.title) ? modal.title : '' }
                  placeholder={'...'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            bannerSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="ModalSideContentTwin Single">
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Ondertitel</div>
                <input
                  ref={inputSubtitle}
                  key={ (modal && modal.subtitle) ? modal.subtitle : '' }
                  defaultValue={ (modal && modal.subtitle) ? modal.subtitle : '' }
                  placeholder={'...'}
                  className={`ModalSideContentInputInput`}
                  onKeyUp={
                    (event) => {
                      const key = event.key;

                      switch(key){
                        case "Enter":
                          if(!loading){
                            bannerSave();
                          }
                        break;
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="modalCouponTitleDivider" />

            <div className="ModalSideContentTwin">
              <div className="ModalSideContentInput NoFlex">
                <div className="ModalSideContentInputPre">Sticker</div>
                { (!hasSticker)
                ?
                <div className="ModalSideContentInputOptions">
                  <a onClick={
                    () => {

                      setHasSticker(true);
                    }
                  } className="ModalSideContentInputOptionsAdd">
                    <RiAddCircleFill style={{ fontSize: 14 }} />
                    <span className="ModalSideContentInputOptionsAddText">Toevoegen</span>
                  </a>
                </div>
                :
                <div className="SettingsSticker">
                  <input
                    ref={inputStickerPre}
                    defaultValue={ (modal && modal.sticker) ? modal.sticker.pre : '' }
                    placeholder={'VANAF'}
                    className={`SettingsStickerInput`}
                  />
                  <input
                    ref={inputStickerText}
                    defaultValue={ (modal && modal.sticker) ? modal.sticker.text : '' }
                    placeholder={'50%'}
                    className={`SettingsStickerInput`}
                  />
                  <input
                    ref={inputStickerPost}
                    defaultValue={ (modal && modal.sticker) ? modal.sticker.post : '' }
                    placeholder={'KORTING'}
                    className={`SettingsStickerInput`}
                  />
                  <a onClick={
                    () => {

                      setHasSticker(false);
                    }
                  } className="SettingsStickerClear">
                    <RiDeleteBin5Line style={{ fontSize: 14 }} />
                  </a>
                </div>
                }
              </div>
              <div className="ModalSideContentInput">
                <div className="ModalSideContentInputPre">Actie</div>

                <div className="ModalSideContentItems">

                  <div onClick={
                    () =>{

                      setModal( modalOld => {
                        return {
                          ...modalOld,
                          c2a: {
                            text: 'Winkels bekijken',
                            url: 'stores'
                          }
                        }
                      })
                      setUrlSubSelected();

                    }
                  } className={`ModalSideContentItem`}>
                    <div className="DropDownDropdownItemText">{ 'Navigeren naar Winkels' }</div>
                    { (modal && modal.c2a && (modal.c2a.url == 'stores')) &&
                    <span className="DropDownDropdownItemCircle">
                      <RiCheckFill style={{ fontSize: 12, color: '#fff' }} />
                    </span>
                    }
                  </div>

                  <div onClick={
                    () =>{

                      setModal( modalOld => {
                        return {
                          ...modalOld,
                          c2a: {
                            text: 'Alle producten',
                            url: 'categorie/alle'
                          }
                        }
                      })
                      setUrlSubSelected();

                    }
                  } className={`ModalSideContentItem`}>
                    <div className="DropDownDropdownItemText">{ 'Alle producten bekijken' }</div>
                    { (modal && modal.c2a && (modal.c2a.url == 'categorie/alle')) &&
                    <span className="DropDownDropdownItemCircle">
                      <RiCheckFill style={{ fontSize: 12, color: '#fff' }} />
                    </span>
                    }
                  </div>

                  <div onClick={
                    () =>{

                      setModal( modalOld => {
                        return {
                          ...modalOld,
                          c2a: {
                            text: 'Producten bekijken',
                            url: 'categorie/'
                          }
                        }
                      })
                      setUrlSubSelected();
                      fetchSubItems('category');

                    }
                  } className={`ModalSideContentItem`}>
                    <div className="DropDownDropdownItemText">{ 'Navigeren naar Category' }</div>
                    { (modal && modal.c2a && (modal.c2a.url != 'categorie/alle' && (modal.c2a.url).includes('categorie/'))) &&
                    <span className="DropDownDropdownItemCircle">
                      <RiCheckFill style={{ fontSize: 12, color: '#fff' }} />
                    </span>
                    }
                  </div>

                  <div onClick={
                    () =>{

                      setModal( modalOld => {
                        return {
                          ...modalOld,
                          c2a: {
                            text: 'Product bekijken',
                            url: 'product/'
                          }
                        }
                      })
                      setUrlSubSelected();
                      fetchSubItems('product');


                    }
                  } className={`ModalSideContentItem`}>
                    <div className="DropDownDropdownItemText">{ 'Navigeren naar Product' }</div>
                    { (modal && modal.c2a && ((modal.c2a.url).includes('product/'))) &&
                    <span className="DropDownDropdownItemCircle">
                      <RiCheckFill style={{ fontSize: 12, color: '#fff' }} />
                    </span>
                    }
                  </div>

                  <div onClick={
                    () =>{

                      setModal( modalOld => {
                        return {
                          ...modalOld,
                          c2a: {
                            text: 'Blogs bekijken',
                            url: 'blogs'
                          }
                        }
                      })
                      setUrlSubSelected();

                    }
                  } className={`ModalSideContentItem`}>
                    <div className="DropDownDropdownItemText">{ 'Alle blogs bekijken' }</div>
                    { (modal && modal.c2a && (modal.c2a.url == 'blogs')) &&
                    <span className="DropDownDropdownItemCircle">
                      <RiCheckFill style={{ fontSize: 12, color: '#fff' }} />
                    </span>
                    }
                  </div>

                  <div onClick={
                    () =>{

                      setModal( modalOld => {
                        return {
                          ...modalOld,
                          c2a: {
                            text: 'Blog lezen',
                            url: 'blog/'
                          }
                        }
                      })
                      setUrlSubSelected();
                      fetchSubItems('blog');

                    }
                  } className={`ModalSideContentItem`}>
                    <div className="DropDownDropdownItemText">{ 'Navigeren naar Blog' }</div>
                    { (modal && modal.c2a && ((modal.c2a.url).includes('blog/'))) &&
                    <span className="DropDownDropdownItemCircle">
                      <RiCheckFill style={{ fontSize: 12, color: '#fff' }} />
                    </span>
                    }
                  </div>

                  { (modal && modal.c2a &&  (!['categorie/alle'].includes(modal.c2a.url)) && (/.+\//.test(modal.c2a.url))) &&
                  <div className="ModalSideContentInputPre" style={{ marginTop: 10 }}>Sub item</div>
                  }
                  { (modal && modal.c2a &&  (!['categorie/alle'].includes(modal.c2a.url)) && (/.+\//.test(modal.c2a.url))) &&
                  <div className="ModalSideContentInputFull">



                    <DropDown
                      key={JSON.stringify(subType)}
                      selected={(urlSubSelected) ? urlSubSelected.value : 'Selecteer item'}
                      list={(urlSubs).map( (type, index) => { return { key: type.key, value: type.value }})}
                      onSearch={
                        (query) => {

                          if(subType != ''){
                            fetchSubItems(subType, query);
                          }
                        }
                      }
                      onSelect={
                        (item) => {

                          setUrlSubSelected(item);

                          const b_regex = /^(.*?)\//;
                          const b_match = (modal.c2a.url).match(b_regex);
                          let b_type = b_match ? b_match[1] : (modal.c2a.url);

                          if(b_type != ''){
                            const b_url = b_type + '/' + item.key;

                            let b_text = 'Bekijken';
                            switch(b_type){
                              case "category":
                                b_text = 'Producten bekijken';
                              break;
                              case "product":
                                b_text = 'Product bekijken';
                              break;
                              case "blog":
                                b_text = 'Artikel lezen';
                              break;
                            }


                            setModal( modalOld => {
                              return {
                                ...modalOld,
                                c2a: {
                                  text: b_text,
                                  url: b_url
                                }
                              }
                            });
                          }

                        }
                      }
                    />
                  </div>
                  }

                  <div className="ModalSideContentInputPre" style={{ marginTop: 10 }}>Actie uitgeschreven</div>
                  <div className="ModalSideContentInputSplitted">
                      <input
                        ref={inputC2aUrl}
                        key={ JSON.stringify(modal) }
                        defaultValue={ (modal && modal.c2a) ? (modal.c2a.url) : '' }
                        placeholder={'...'}
                        className={`ModalSideContentInputInput Disabled`}
                        disabled={true}
                        onChange={
                          (event) => {
                            const val = event.target.value;

                            setModal( modalOld => {
                              return {
                                ...modalOld,
                                c2a: {
                                  text: 'Bekijken',
                                  url: val
                                }
                              }
                            });
                            setTimeout( () => {
                            inputC2aUrl.current?.focus();
                            }, 1)
                          }
                        }
                        onKeyUp={
                          (event) => {
                            const key = event.key;

                            switch(key){
                              case "Enter":
                                if(!loading){
                                  bannerSave();
                                }
                              break;
                            }
                          }
                        }
                      />

                  </div>


                </div>
              </div>

            </div>

          </div>

          <div className="modalContentOptions LightBorder">
            <a onClick={
              () => {

                bannerSave();
              }
            } className="modalContentOptionsButton C2a">Opslaan</a>
            <a onClick={
              () => {

                setDetailsPre();
              }
            } className="modalContentOptionsButton">Annuleren</a>
          </div>

        </div>
    )
  }



  useEffect( () => {

    const page_parsed = parseInt(subview_data);

    if(page_parsed > 1){
      // Get main view
      setPage(page_parsed);
      fetchBanners(page_parsed);
    }else{

    }

    fetchBanners();

    setSettingsHeaderPre(
      <div className="SettingsContentContentNavigation HideDesktop">
        <a onClick={
          () => {

            setDetailsPre({
              id: 0,
            });
          }
        } className="OrderItemOptionsButton AccentHover">
          <RiAddCircleLine style={{ fontSize: 14 }} />
          <span className="OrderItemOptionsButtonText">Aanmaken</span>
        </a>
      </div>
    )
  }, []);

  useEffect( () => {

    if(!loading){
      // controller.abort();
      fetchBanners();
    }

  }, [searchQuery])


  const onSortItems = (items) => {

    setBanners([...items]);

    if(timeoutId){
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    const newTimeoutId = setTimeout(() => {

      bannerSaveOrder(items);

      setTimeoutId(null);
    }, 1000);
    setTimeoutId(newTimeoutId);
  }

  console.log(banners)

  const banners_items = (banners).map( (banner_item, index) => {

    if(!banner_item) return false;

    const { category } = banner_item;

    return (
      <SortItem onSortItems={onSortItems} sortId={index} items={banners} className="OrderItem" key={'banner_item_' + banner_item.id}>
        {/* <div className="OrderItemDetailsText Order">{ banner_item.order }</div> */ }
        <div className="OrderItemDetailsText First Active">
          <span className={`OrderItemDetailsTextOption Circle ${ (banner_item.active) ? 'Success' : 'Error' }`}>
            { (banner_item.active)
            ?
            <RiCheckFill style={{ fontSize: 12 }} />
            :
            <RiCloseFill style={{ fontSize: 12 }} />
            }
          </span>
        </div>
        <div className="OrderItemDetailsText Image">
          <div className="OrderItemDetailsTextImage" style={{ backgroundImage: `url(${banner_item.image})` }} />
        </div>
        <div className="OrderItemDetailsText Title Bold">{ banner_item.title }</div>
        <div className="OrderItemDetailsText Date">{ banner_item.timestamp_last?.date }</div>
        <div className="OrderItemDetailsText Page">{banner_item.page}</div>

        <div className="OrderItemOptions">

          <a onClick={
            () => {

              toggleBannerActive(banner_item.id, index);

            }
          } className={`OrderItemOptionsButton ${ (banner_item.active) ? 'DeleteInverted' : 'AddInverted' }`}>
            { (banner_item.active)
            ?
            <RiToggleFill style={{ fontSize: 14 }} />
            :
            <RiToggleLine style={{ fontSize: 14 }} />
            }
            <span className="OrderItemOptionsButtonText">{ (banner_item.active) ? 'Deactiveren' : 'Activeren' }</span>
          </a>

          <a onClick={
            () => {

              setDetailsPre(banner_item);

            }
          } className="OrderItemOptionsButton Edit">
            <RiFileShield2Line style={{ fontSize: 14 }} />
            <span className="OrderItemOptionsButtonText">{ 'Bewerken' }</span>
          </a>
        </div>

      </SortItem>
    )
  })


  return (
    <div className="SettingsContentContent List">


      <div className="SettingsMessageText ShowMobile">Voorlopig alleen op desktop beschikbaar.</div>

      <div className={`settingsContentReal HideDesktop`}>
        <div className="SettingsContentNavigation">
          { /* <div className="SettingsContentNavigationItem Order">Volg.</div> */ }
          <div className="SettingsContentNavigationItem First Active">Actief</div>
          <div className="SettingsContentNavigationItem Image">Foto</div>
          <div className="SettingsContentNavigationItem Title">Naam</div>
          <div className="SettingsContentNavigationItem Date">Aangepast</div>
          <div className="SettingsContentNavigationItem OptionsBig">Pagina</div>
        </div>

        <div className="GlobSettingsScroll" ref={scrollView}>

          { (loading) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }

          { (banners_items.length > 0)
          ?
          <div className='sortable-list'>
          { banners_items }
          </div>
          :
          <div className="SettingsMessageText">Geen banners.</div>
          }
        </div>

        { bannersPagination() }
      </div>


      <DetailView modal={modal} setDetailsPre={setDetailsPre}>
      { modalView() }
      </DetailView>

    </div>
  )
}

export default Banners;
