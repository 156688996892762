import '../styles/ProductDetails.css';
import config from '../constants/config';
import Product from '../components/Product';
import {
  RiArrowRightSFill,
  RiArrowLeftFill,
  RiArrowRightFill,
  RiAddFill,
  RiSubtractFill,
  RiShoppingBagFill,
  RiHomeHeartFill,
  RiArrowGoBackFill,
  RiStore2Fill,
  RiFolderAddFill,
  RiAddCircleFill
} from "react-icons/ri";
import { useState, useEffect } from 'react';
import loadingAnimation from '../animations/loading_circle.json';
import loginAnimation from '../animations/app_showcase.json';
import Lottie from "lottie-react";
import axios from 'axios';

import { Tooltip as DaTooltip } from "react-tooltip";


function Products(props) {
  const { subview,
    view, setViewPre,
    setModalPre,
    viewData,
    account,
    token,
    setCartCountPre,
    accountInfo
   } = props;
   const [ loading, setLoading ] = useState(false);
   const [ adding, setAdding ] = useState(false);
   const [ mounted, setMounted ] = useState(false);
   const [ showStock, setShowStock ] = useState(false);
   const [ qty, setQty ] = useState(1);
   const [ productsRelated, setProductsRelated ] = useState([]);
   const [ product, setProduct ] = useState(null);
   const [ imageLoaded, setImageLoaded ] = useState(true);
   const [ addedLater, setAddedLater ] = useState(false);
   const [ productStock, setProductStock ] = useState([]);

   let responseTimer = null;

   const [ productImage, setProductImage ] = useState(null);

   // Get subview
   let id = 0;
   if(subview){
     const subviews = (subview + '').split('-');
     id = subviews[0];
   }



   const saveLater = () => {

     axios.post( config.api.url + 'account/cart/later/save', {
       hash: token,
       item: {
         qty: qty,
         product: product
       }
     }, {
         crossdomain: true,
         headers: {
           'api': config.api.key
         }
     })
     .then((json) => {
       const { success, response, data } = json.data;

       if(success){
         accountInfo();
         setAddedLater(true);
       }

     })
     .catch(function (error) {

     });
   }

  const addToCart = () => {

    setAdding(true);
    axios.post( config.api.url + 'product/add', {
      product_id: id,
      qty: qty,
      hash: token
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;


      if(success && product){

        const cartThumbsItems = [...Array(qty)];
        let animationDuration = 2500;
        const cartThumbs = (cartThumbsItems).map( (ca, ci) => {

          const randomDelay = Math.random() * (175 - 150) + 150;
          const cleanAnimationDelay = (ci * randomDelay);

          const animationDelay = cleanAnimationDelay + 800;
          animationDuration = animationDuration + (randomDelay);

          return (
            <div className="modalContentCartAnimation" style={{
              animationDelay: (animationDelay + 'ms')
            }} key={'add_cart_item_' + ci}>
              <img src={product.image} />
            </div>
          )
        })

        if(responseTimer){
          clearTimeout(responseTimer);
        }
        responseTimer = setTimeout( () => {

          if(mounted){

            setModalPre({
              title: (success) ? 'Toegevoegd.' : 'Oops.',
              subtitle: response,
              content: (
                <>
                  <div className="modalContentCartAnimationView Closing" style={{
                  }}>
                    <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/connected.png'})` }} />


                  </div>
                  <div className="modalContentOptions NoBorder">
                    <a onClick={
                      () => {
                        setViewPre('winkelmandje');
                        setModalPre();
                      }
                    } className="modalContentOptionsButton C2a AccentHover">Afrekenen</a>
                    <a onClick={
                      () => {

                        setModalPre();
                      }
                    } className="modalContentOptionsButton">Verder winkelen</a>
                  </div>
                </>
              )
            })
          }

        }, animationDuration);

        setCartCountPre(qty);
        setModalPre({
          title: (success) ? 'Toegevoegd.' : 'Oops.',
          close: {
            action: () => {

              if(responseTimer){
                clearTimeout(responseTimer);
              }

            }
          },
          subtitle: response,
          content: (
            <>
              <div className="modalContentCartAnimationView" style={{
              }}>
                <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/connected.png'})` }} />

                { cartThumbs }

                <div className="modalContentCartAnimationFill" />
                <div className="modalContentCartAnimationFill Top" />

              </div>
              <div className="modalContentOptions NoBorder">
                <a onClick={
                  () => {
                    if(responseTimer){
                      clearTimeout(responseTimer);
                    }
                    setViewPre('winkelmandje');
                    setModalPre();
                  }
                } className="modalContentOptionsButton C2a AccentHover">Afrekenen</a>
                <a onClick={
                  () => {
                    if(responseTimer){
                      clearTimeout(responseTimer);
                    }
                    setModalPre();
                  }
                } className="modalContentOptionsButton">Verder winkelen</a>
              </div>
            </>
          )
        })


        setTimeout( () => {
          setAdding(false);
        }, 300);
      }

    })
    .catch(function (error) {

      setAdding(false);
    });


  }


  const fetchProduct = () => {

    setLoading(true);
    axios.post( config.api.url + 'product/info', {
      product_id: id,
      auth: token
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){
        const product = data.product;

        if(product){
          setProduct(product);

          // setModalPre({
          //   title: (success) ? 'Toegevoegd.' : 'Oops.',
          //   subtitle: response,
          //   content: (
          //     <>
          //       <div className="modalContentCartAnimationView">
          //         <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/connected.png'})` }} />
          //
          //         <div className="modalContentCartAnimation">
          //           <img src={'https://www.da.nl/media/fit?width=300&height=711&type=webp&file=catalog/product/8/7/8718951266193_a1n1_1.png'} />
          //         </div>
          //
          //       </div>
          //       <div className="modalContentOptions NoBorder">
          //         <a onClick={
          //           () => {
          //             setViewPre('winkelmandje');
          //             setModalPre();
          //           }
          //         } className="modalContentOptionsButton C2a AccentHover">Afrekenen</a>
          //         <a onClick={
          //           () => {
          //
          //             setModalPre();
          //           }
          //         } className="modalContentOptionsButton">Verder winkelen</a>
          //       </div>
          //     </>
          //   )
          // })

          const images = product.images;
          if(images){
            setProductImage(images[0]);
            setImageLoaded(true);
          }
        }

        setLoading(false);
      }

    })
    .catch(function (error) {

      setLoading(false);
    });

  }

  function convertObjectToArray(inputObject) {
    return Object.keys(inputObject).map(key => {
      return {
        name: key,
        data: inputObject[key]
      };
    });
  }

  const fetchProductStock = () => {


    setLoading(true);
    axios.post( config.api.url + 'product/stock', {
      product_id: id,
      auth: token
    }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
          'session': props.sessionHash
        }
    })
    .then((json) => {
      const { success, response, data } = json.data;

      if(success){
        const stock = convertObjectToArray(data.stock);

        if(stock && stock.length > 0){
          setShowStock(true);
          setProductStock(stock);
        }

        setLoading(false);
      }

    })
    .catch(function (error) {

      setLoading(false);
    });

  }

  const fetchProductsRelated = (type = 'related') => {

   axios.post( config.api.url + 'products/related', {
     product_id: id,
   }, {
       crossdomain: true,
       headers: {
         'api': config.api.key,
         'session': props.sessionHash
       }
   })
   .then((json) => {
     const { success, response, data } = json.data;

     if(success){
       const products = data.products;

       if(products){
         setProductsRelated(products);
       }

     }

   })
   .catch(function (error) {


   });

  }

   useEffect( () => {

     if(id > 0){

       // RESET
       fetchProduct();
       fetchProductsRelated();
       setQty(1);
       setAddedLater(false);
       setShowStock(false);

     }

   }, [id]);

   useEffect( () => {

     setMounted(true);
     return () => {

       setMounted(false);
     }
   }, []);


   const productItemsView = () => {

     const productItems = productsRelated.map( (prod_item, prod_index) => {

       return (
         <Product
           {... props}
           item={prod_item}
           key={'product_item_' + prod_index}
           token={token}
           setModalPre={setModalPre}
         />
       )
     })

     return (
       <>
         { (productsRelated.length == 0) &&
         <div className="LoginLoadingOverlay InFill">
           <div className="LoginLoading">
             <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
           </div>
         </div>
         }
         { productItems }
       </>
     );
   }

   const updateQTY = (qty = 0) => {
     if(qty > 0){
       setQty(qty);
     }
   }


   let productPrice = 0.00;
   let productOnePrices = ['00', '00'];
   let productPrices = ['00', '00'];

   if(product){
     productPrice = parseFloat(product.price).toFixed(2);
     productOnePrices = (productPrice + '').split('.');

     productPrices = (productPrice + '').split('.');
     const subTotals = (parseFloat(qty * parseFloat(productPrice))).toFixed(2);

     if(qty > 1){
       productPrices = (subTotals + '').split('.');
     }
   }

  const product_key = (viewData && viewData.timestamp) ? viewData.timestamp : 'static';


  return (
    <div className="Product" key={'Product_' + product_key}>
      <div className="FixedWidth">

        <div className="ProductNavigationWrapper">
          <div className="ProductNavigation">
            <a href={config.homepage} onClick={
              (event) => {

                setViewPre('home')

                event.preventDefault();
              }
            } className="ProductNavigationHome">
              <RiHomeHeartFill />
            </a>

            <a href={ config.homepage + 'categorie/alle'} onClick={
              (event) => {

                setViewPre('categorie/alle');

                event.preventDefault();
              }
            } className="ProductNavigationItem">
              <div className="ProductNavigationItemText" style={{ marginRight: 0 }}>Producten</div>
            </a>

            { (viewData && viewData.previous) &&
            <a href={ config.homepage + viewData.previous} onClick={
              (event) => {

                setViewPre(viewData.previous);

                event.preventDefault();
              }
            } className="ProductNavigationItem">
              <div className="ProductNavigationItemText">Terug naar {`vorige pagina`}</div>
              <RiArrowGoBackFill style={{ fontSize: 11 }} />
            </a>
            }
          </div>
        </div>

        <div className="ProductDetails">
          <div className="ProductDetailsImageWrapper">
            <div className="ProductDetailsImageView">
              { (product && productImage) &&
              <div className="ProductDetailsImage">
                { (imageLoaded)
                ?
                <img src={productImage.image} draggable={false} onError={
                  () => {
                    setImageLoaded(false);
                  }
                } />
                :
                <>

                </>
                }
              </div>
              }
            </div>
            { (product && product.images) &&
            <div className="ProductDetailsImageSide">
              { (product.images).map( (thumb, t_ind) => {
                return (
                  <a onMouseEnter={
                    () => {
                      setProductImage(thumb);
                      setImageLoaded(true);
                    }
                  } className="ProductDetailsImageSideItem" key={'thumb_' + t_ind}>
                    <div className="ProductDetailsImageSideItemImage" style={{ backgroundImage: `url('${ (thumb.image) }')` }} />
                  </a>
                )
              }) }
            </div>
            }
          </div>

          { (product) &&
          <div className="ProductDetailsContent">

            <div className="ProductDetailsContentTitle">{ product.title }</div>
            <div className="ProductDetailsContentDescr">{ product.category.category }</div>
            <div className="ProductDetailsContentPriceView">
              { (qty > 1) &&
              <div className="ProductDetailsContentPrice Pre">
                <div className="ProductDetailsContentPricePre">SRD</div>
                <div className="ProductDetailsContentPriceAmount">{ productOnePrices[0] }</div>
                <div className="ProductDetailsContentPriceSubAmount">{ productOnePrices[1] }</div>
              </div>
              }
              <div className="ProductDetailsContentPrice">
                <div className="ProductDetailsContentPricePre">SRD</div>
                <div className="ProductDetailsContentPriceAmount">{ productPrices[0] }</div>
                <div className="ProductDetailsContentPriceSubAmount">{ productPrices[1] }</div>
              </div>

            </div>
            <div className="ProductDetailsContentAddWrapperView">
              { (product && product.stock > 0) ?
              <div className="ProductDetailsContentAddWrapper">

                <div className="ProductDetailsContentPriceFlipper">
                  <a onClick={
                    () => {
                      const removeQty = (qty > 1) ? (qty - 1) : 1;
                      updateQTY(removeQty);
                    }
                  } className="ProductDetailsContentPriceFlipperItem">
                    <RiSubtractFill />
                  </a>
                  <div className="ProductDetailsContentPriceFlipperText">{ qty }</div>
                  <a onClick={
                    () => {
                      const addQty = (qty + 1);
                      if(addQty <= product.stock){
                        updateQTY(addQty);
                      }else{
                        updateQTY(qty);
                      }
                    }
                  } className="ProductDetailsContentPriceFlipperItem">
                    <RiAddFill />
                  </a>
                </div>

                <a onClick={
                  () => {

                    if(account){
                      addToCart();
                    }else{
                      setModalPre({
                        title: 'Je bent niet ingelogd.',
                        subtitle: 'Maak eenmalig een account aan bij DA en shop met veel plezier!',
                        content: (
                          <>
                            <div className="modalContentContent Row">
                              <div className="modalContentAnimationWrapper">
                                <Lottie className="modalContentAnimation" animationData={loginAnimation} loop={true} />
                              </div>
                              <div className="LoginFormSignupView">
                                <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
                                <div className="LoginFormHeader">
                                  <div className="LoginFormHeaderTitle">Nog geen <b>DA</b> account?</div>
                                </div>

                                <a onClick={
                                  () => {
                                    setViewPre('account/registreren');
                                    setModalPre();
                                  }
                                } className="LoginFormOptionsButton Signup">
                                  <div className="LoginFormOptionsButtonText">Registreren</div>
                                  <RiArrowRightFill />
                                </a>

                                <div className="LoginFormOrView">
                                  <div className="LoginFormOrDivider" />
                                  <div className="LoginFormOr">OF</div>
                                  <div className="LoginFormOrDivider" />
                                </div>

                                <a onClick={
                                  () => {
                                    setViewPre('account/login');
                                    setModalPre();
                                  }
                                } className="LoginFormOptionsButton Margined">
                                  <div className="LoginFormOptionsButtonText">Inloggen</div>
                                  <RiArrowRightFill />
                                </a>

                              </div>
                            </div>
                            <div className="modalContentOptions NoBorder">
                              <a onClick={
                                () => {

                                  setModalPre();
                                }
                              } className="modalContentOptionsButton">Annuleren</a>
                            </div>
                          </>
                        )
                      })
                    }

                  }
                } className="ProductDetailsContentAdd AccentHover">
                  <div className="ProductDetailsContentAddText">Toevoegen aan winkelmandje</div>
                  <RiShoppingBagFill />
                </a>
                { (!addedLater) &&
                <a id={'later-add'} onClick={
                  () => {

                    if(account){
                      saveLater();
                    }else{
                      setModalPre({
                        title: 'Je bent niet ingelogd.',
                        subtitle: 'Maak eenmalig een account aan bij DA en shop met veel plezier!',
                        content: (
                          <>
                            <div className="modalContentContent Row">
                              <div className="modalContentAnimationWrapper">
                                <Lottie className="modalContentAnimation" animationData={loginAnimation} loop={true} />
                              </div>
                              <div className="LoginFormSignupView">
                                <div className="GlobTexture"  style={{ backgroundSize: 150, backgroundImage: `url(${ config.homepage + 'data/textures/swirl.png'})` }} />
                                <div className="LoginFormHeader">
                                  <div className="LoginFormHeaderTitle">Nog geen <b>DA</b> account?</div>
                                </div>

                                <a onClick={
                                  () => {
                                    setViewPre('account/registreren');
                                    setModalPre();
                                  }
                                } className="LoginFormOptionsButton Signup">
                                  <div className="LoginFormOptionsButtonText">Registreren</div>
                                  <RiArrowRightFill />
                                </a>

                                <div className="LoginFormOrView">
                                  <div className="LoginFormOrDivider" />
                                  <div className="LoginFormOr">OF</div>
                                  <div className="LoginFormOrDivider" />
                                </div>

                                <a onClick={
                                  () => {
                                    setViewPre('account/login');
                                    setModalPre();
                                  }
                                } className="LoginFormOptionsButton Margined">
                                  <div className="LoginFormOptionsButtonText">Inloggen</div>
                                  <RiArrowRightFill />
                                </a>

                              </div>
                            </div>
                            <div className="modalContentOptions NoBorder">
                              <a onClick={
                                () => {

                                  setModalPre();
                                }
                              } className="modalContentOptionsButton">Annuleren</a>
                            </div>
                          </>
                        )
                      })
                    }

                  }
                } className="ProductDetailsContentAdd Circle Later">
                  <RiAddCircleFill />
                </a>
                }

                <DaTooltip
                  anchorId="later-add"
                  place="right"
                  content={ 'Opslaan voor later' }
                />
              </div>
              :
              <div className="ProductDetailsContentInfoStock">
                <div className="ProductDetailsContentInfoStockText">Niet in voorraad</div>
              </div>
              }
            </div>

            <div className="ProductDetailsContentInformation">
              <div className="ProductDetailsContentInformationTitle">Productbeschrijving</div>
              <div className="ProductDetailsContentInformationText" dangerouslySetInnerHTML={{ __html: product.descr }} />
            </div>

            {/* <div className="ProductDetailsContentInformation">
              <div className="ProductDetailsContentInformationTitle">Voorraad</div>
              { (!showStock && !loading) &&
              <div onClick={ () => {

                fetchProductStock();

              } } className="ProductDetailsContentInformationButtons">
                <div className="ProductDetailsContentInformationButton">
                  <div className="ProductDetailsContentInformationButtonText">Voorraden bekijken</div>
                  <RiFolderAddFill />
                </div>
              </div>
              }

              { (loading && product) &&
              <div className="LoginLoading" style={{ marginTop: 10 }}>
                <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
              </div>
              }

              <div className={`ProductDetailsContentInformationItems Hidden ${ (showStock && productStock.length > 0) ? 'Show' : ''}`}>

                { (productStock).map( (stockItem, index) => {
                  
                  const store = (stockItem.name == 'DA Suriname Magazijn') ? 'DA Webshop' : stockItem.name;
                  
                  const stock = (stockItem.data && stockItem.data[0]) ? ((stockItem.data[0]).stockQuantity) : 'NVT';
                  return (
                  <div className="ProductDetailsContentInformationItem" key={'stock_store_'+index}>
                    <div className="ProductDetailsContentInformationItemPre">{ store }</div>
                    <div className={`ProductDetailsContentInformationItemValue`} style={{ color: (stock == 0) ? '#d63636' : 'inherit' }}>{ stock }</div>
                  </div>
                  )
                })
                }

              </div>
            </div> */}


          </div>
          }
        </div>



        <div className="ProductDetailsRelated">

          { (loading && productsRelated.length == 0) &&
          <div className="LoginLoadingOverlay">
            <div className="LoginLoading">
              <Lottie className="LoginLoadingAnimation" animationData={loadingAnimation} loop={true} />
            </div>
          </div>
          }

          <div className="ProductDetailsRelatedTitle">Gerelateerde producten</div>

          <div className="ProductDetailsRelatedItems">
          { productItemsView() }
          </div>

        </div>


      </div>
    </div>
  );
}

export default Products;
