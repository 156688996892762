export default {
  homepage: 'https://da.sr/',
  // homepage: 'http://192.168.10.108:3000/',
  api: {
    // url: 'http://localhost/da_web/api/',
    url: 'https://da.sr/api/',
    key: 'YG9N9JuUJJH2HhZJZcUR'
  },

  title: {
    pre: 'DA Drogist | Suriname'
  },

  google: {
    api: 'AIzaSyByicflh_u69XeDmmW8Q0ugl2N_Bj8pnCs',
    style: [],

    login: {
      clientId: '742439354351-b2pomofm7ekhsv1flsidpa5g8u0og45q.apps.googleusercontent.com',
      clientSecret: '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'
    }
  }, 
  facebook: {
    login: {
      appId: '260854646491570'
    }
  }
}
